<script lang="ts">
  import Home from "./routes/Home.svelte";
  import Blog from "./routes/Blog.svelte";
  import Resume from "./routes/Resume.svelte";
  import NotFound from "./routes/NotFound.svelte";

  import Router from "svelte-spa-router";
  import QuickLinks from "./components/QuickLinks.svelte";

  const routes = {
    "/": Home,
    "/resume": Resume,
    "/blog": Blog,
    "/blog/:id": Blog,
    "*": NotFound,
  };
</script>

<div class="container">
  <div class="body">
    <div class="sidebar">
      <QuickLinks />
    </div>

    <div class="content">
      <Router {routes} />
    </div>
  </div>
</div>

<style lang="scss">
  :global(body) {
    background-color: #16193b;
    color: #bdddf9;
    font-weight: 400;
  }

  :global(a),
  :global(a:visited) {
    color: #e7c7d0;
  }

  @media (prefers-color-scheme: dark) {
    :global(img) {
      opacity: 0.85;
      transition: opacity 0.25s ease-in-out;
    }

    :global(img:hover) {
      opacity: 1;
    }
  }

  @media (prefers-color-scheme: light), print {
    :global(body) {
      background-color: white;
      color: black;
    }

    :global(a),
    :global(a:visited) {
      color: darkorange;
    }
  }

  :global(html) {
    font-size: 20px;
  }

  :global(body) {
    overflow: hidden;
    font-family: "Lato", sans-serif;
    margin: 0;
  }

  :global(a),
  :global(a:visited) {
    background-color: transparent;
    text-decoration: none;
  }

  :global(a:hover) {
    text-decoration: underline;
  }

  :global(h1) {
    font-size: 2rem;
    text-decoration: underline;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  :global(h2) {
    font-size: 1.75rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 960px) {
    :global(body) {
      overflow: unset;
    }
  }

  .container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .body {
    display: grid;
    grid-template-columns: 3fr 5fr;
    overflow: hidden;
    justify-content: center;
  }

  @media (max-width: 2000px) {
    .body {
      grid-template-columns: 1fr 4fr;
    }
  }

  .content {
    overflow-y: scroll;
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-width: 50vw;
    max-width: 1200px;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    margin-left: auto;
    padding-top: 2rem;
  }

  @media (max-width: 960px) {
    .body {
      grid-template-columns: 1fr;
      align-content: start;
    }

    .sidebar {
      margin-left: 0;
      padding-top: 0;
    }

    .content {
      padding-top: 1rem;
      width: 100vw;
    }
  }

  // hide scrollbar
  .content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .content::-webkit-scrollbar {
    display: none;
  }
</style>
