<script lang="ts">
  import { fade } from "svelte/transition";
  import { link } from "svelte-spa-router";
</script>

<svelte:head>
  <title>rugh.dev</title>
  <!-- svelte-ignore component-name-lowercase -->
  <link rel="canonical" href="https://rugh.dev/" />
  <meta property="og:url" content="https://rugh.dev/" />
  <meta property="og:title" content="Stephen Rugh" />
  <meta name="description" content="Stephen Rugh's Website" />
  <meta property="og:description" content="Stephen Rugh's Website" />
</svelte:head>

<main id="intro" in:fade={{ duration: 250 }}>
  Hi, I'm Stephen.
  <p>I'm a software developer from Austin, TX.</p>
  <p>
    Crafting beautiful, performant, engaging web experiences are my bread and
    butter.
  </p>
  <p>
    I enjoy working with organizations that value and cultivate an environment
    were this is possible.
  </p>
  <p>
    I currently work at Adobe but you can see my past work on
    <a href="/resume" use:link>my resume</a>.
  </p>
  <p>
    If you'd like to reach me, please send me an
    <a href="mailto:stephen@rugh.dev">email</a>.
  </p>
</main>

<style>
  main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
