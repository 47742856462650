<script>
  import { fade } from "svelte/transition";
  import { link } from "svelte-spa-router";
  import DarkMode from "posts/DarkMode.svelte";

  // You need to define the component prop "params"
  export let params = {};

  import { onMount } from "svelte";
  import CodeOnlyButton from "../components/CodeOnlyButton.svelte";
  onMount(async () => {
    window.hljs.highlightAll();
  });
</script>

<svelte:head>
  <title>rugh.dev - blog</title>
</svelte:head>

<main id="blog" in:fade={{ duration: 250 }}>
  {#if !params.id}
    <div>
      <ul>
        <li>
          <a href="/blog/1" use:link>Dark Mode</a>
        </li>
      </ul>
    </div>
  {:else if params.id === "1"}
    <CodeOnlyButton />
    <!-- TODO: dynamic loading? -->
    <DarkMode />
  {:else}
    <div>
      Blog not found. Try <a href="/blog/" use:link aria-label="blogs"
        >another</a
      >.
    </div>
  {/if}
</main>

<style>
  main {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1200px;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-position: inside;
  }

  :global(#blog code) {
    background: #f3f3f3;
    border-radius: 5px;
  }

  @media (prefers-color-scheme: dark) {
    :global(#blog code) {
      background: lightblue;
      color: black;
    }
  }
  :global(#blog img) {
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  @media (max-width: 960px) {
    :global(#blog img) {
      max-width: 85%;
      max-height: 85%;
      object-fit: contain;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
</style>
