<script lang="ts">
  import { fade } from "svelte/transition";
  import { link } from "svelte-spa-router";
</script>

<nav id="quick-links" in:fade={{ duration: 250 }}>
  <a href="/" aria-label="homepage">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-home"
    >
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  </a>
  <a href="/blog" aria-label="blog" use:link>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-edit-3"
      ><path d="M12 20h9" /><path
        d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"
      /></svg
    >
  </a>
  <a href="https://github.com/sirugh" aria-label="github">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-github"
    >
      <path
        d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
      />
    </svg>
  </a>
  <a href="https://www.linkedin.com/in/stephenrugh/" aria-label="linkedin">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-linkedin"
      ><path
        d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"
      /><rect x="2" y="9" width="4" height="12" /><circle
        cx="4"
        cy="4"
        r="2"
      /></svg
    >
  </a>
  <!-- <a href="https://open.spotify.com/user/sirugh" aria-label="spotify">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-music"
    >
      <path d="M9 18V5l12-2v13" />
      <circle cx="6" cy="18" r="3" />
      <circle cx="18" cy="16" r="3" />
    </svg>
  </a> -->
</nav>

<style>
  nav {
    border-right: 1px solid #e7c7d0;
    padding-right: 1rem;
    margin-right: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @media (max-width: 960px) {
    nav {
      display: grid;
      grid-template-columns: unset;
      grid-auto-flow: column;
      justify-content: center;
      gap: 1rem;
      border-right: none;
      border-bottom: 1px solid #e7c7d0;
      padding-right: 0;
      margin-right: 0;
      padding-bottom: 0.5rem;
      padding-top: 1rem;
    }
  }
</style>
