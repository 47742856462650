<script>
  // TODO: Make more robust. Serendipitously keeps the bullet points from the darkmode blog. And keeps the hue "hack"

  // Used to just show the code.
  function hideExcess() {
    document
      .querySelectorAll("p")
      .forEach((element) => (element.style = "display: none;"));
    document
      .querySelectorAll("h2")
      .forEach((element) => (element.style = "display: none;"));

    document.querySelector("#show-code").style = "display:none;";
  }
</script>

<button id="show-code" on:click={hideExcess}>Just show me the code!</button>

<style>
  button {
    border: none;
    background-color: transparent;
    color: #e7c7d0;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-transform: uppercase;
    vertical-align: middle;
    outline: 0;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.5px;
    cursor: pointer;
  }

  @media (prefers-color-scheme: light) {
    button {
      color: darkorange;
    }
  }

  @media print {
    button {
      display: none;
    }
  }
</style>
