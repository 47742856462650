<script>
  import { fade } from "svelte/transition";
</script>

<svelte:head>
  <title>rugh.dev - resume</title>
  <link rel="canonical" href="https://rugh.dev/#/resume" />
  <meta property="og:url" content="https://rugh.dev/#/resume" />
  <meta property="og:title" content="Stephen Rugh's Resume" />
  <meta name="description" content="Stuff I've done." />
  <meta property="og:description" content="Stuff I've done." />
</svelte:head>

<main in:fade={{ duration: 250 }}>
  <section id="employment">
    <h1>Employment</h1>
    <ul>
      <li>
        <h2 class="company-name">Adobe</h2>
        <p>2019 to present</p>
        <p>
          The majority of my time at Adobe was spent working on the
          <a href="https://www.github.com/magento/pwa-studio">
            PWA Studio project</a
          >. PWA Studio is the next frontend of Adobe Commerce's offering and I
          worked on many of its features and upgrades including the REST to
          GraphQL conversion, supporting internationalization, and a
          <a
            href="https://developer.adobe.com/commerce/pwa-studio/guides/general-concepts/talons/"
            >major refactor to MVVM</a
          >. I also collaborated closely with our active community of brilliant
          external open-source developers, and helped facilitate delivery of a
          good number of wonderful contributions.
        </p>
        <p>
          I then spent some time on a <a
            href="https://marketplace.magento.com/magento-channel-manager.html"
            >full stack, greenfield initiative
          </a> that will enable merchants to easily increase their audience through
          connections to various marketplaces. As front end lead, I worked closely
          with product and design to deliver an engaging, seamless user experience
          built on a thin React micro-frontend over a PHP middleware, powered by
          a GraphQL API built on Adobe's cloud computing platform.
        </p>
        <p>
          My latest endeavor at Adobe involves the challenging task of
          integrating Adobe Experience Cloud with the existing Commerce stack.
          I'm leading the engineering team to execute on a solution that will
          join the power of the two existing systems and pave the way for the
          future of Adobe Commerce's Admin experience.
        </p>
      </li>
      <li>
        <h2 class="company-name">Bazaarvoice</h2>
        <p>2014 to 2019</p>
        <p>
          My time at Bazaarvoice was spent working cross-functionally and as a
          team lead on the Ratings & Reviews UI. Our team worked to deploy
          multiple client-side applications using reusable modules that were
          previously contained in a single monolithic application. This system
          provided a much cleaner development experience, increased
          maintainability/scalability, and paved the way for the next generation
          of Bazaarvoice's front-end applications.
        </p>
        <p>
          I also worked on an in-house analytics and data collection SDK that
          was implemented by all of our front-end solutions and API clients. The
          SDK had to be forward and backward compatible with previous analytics
          solutions and provided a huge improvement to the sanity of our data
          and ease of implementation.
        </p>
      </li>
      <li>
        <h2 class="company-name">H-E-B</h2>
        <p>2012 to 2014</p>
        <p>
          Much of my time at HEB was spent learning Java, Spring and Bootstrap
          and JQuery. I developed a fax integration system wherein prescriptions
          could be faxed and automatically ingested/read by OCR thus eliminating
          manual overhead and saving the pharmacy valuable time.
        </p>
        <p>
          The HEB San Antonio Development Center (SADC) helped me get my start
          as a Software Engineer. I am forever grateful for having the
          opportunity to work for a company so involved in the Texas community.
        </p>
      </li>
    </ul>
  </section>
  <section id="projects">
    <h1>Projects</h1>
    <ul>
      <li>
        <a href="https://github.com/sirugh/PlaylistBot">PlaylistBot</a>
      </li>
      <li>
        <a href="https://lessisfewer.netlify.app/">jump game - game jam 2018</a>
      </li>
    </ul>
  </section>
  <section id="links">
    <h1>Links</h1>
    <ul>
      <li>
        <a href="https://www.linkedin.com/in/stephenrugh">Linkedin</a>
      </li>
      <li>
        <a href="/assets/stephen-rugh-resume.pdf">resume</a>
      </li>
    </ul>
  </section>
</main>

<style>
  main {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1200px;
  }

  section {
    padding-bottom: 1rem;
  }
  ul {
    margin: 0;
  }

  #employment li {
    list-style: none;
    padding-bottom: 0.25rem;
  }

  @media (max-width: 960px) {
    .company-name {
      margin-left: 0;
      align-self: center;
    }

    #employment ul {
      padding-inline-start: 0;
    }

    #projects ul,
    #links ul {
      padding-inline-start: 20px;
    }
  }

  @media (max-width: 960px) {
  }
</style>
