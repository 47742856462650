<!-- TODO: SEO for posts that works as nested pages. -->
<script>
  import mdDarkmode from "./darkmode.md";
</script>

<svelte:head>
  <link rel="canonical" href="https://rugh.dev/#/blog/1" />
  <meta property="og:url" content="https://rugh.dev/#/blog/1" />
  <meta property="og:locale" content="en_US" />
  <meta property="og:type" content="article" />
  <meta property="title" content="Darkmode and the Web" />
  <meta property="og:title" content="Darkmode and the Web" />
  <meta
    name="description"
    content="A brief history of darkmode in the web and some implementation tips and suggestions."
  />
  <meta
    property="og:description"
    content="A brief history of darkmode in the web and some implementation tips and suggestions."
  />
  <meta
    property="og:image"
    content="https://rugh.dev/assets/blog/darkmode/a-bad-comparison.jpeg"
  />
  <meta property="og:image:height" content="500" />
  <meta property="og:image:width" content="500" />
  <meta
    property="article:tag"
    content="frontend, darkmode, javascript, html, css"
  />
  <meta
    property="keywords"
    content="frontend, darkmode, javascript, html, css"
  />
  <meta property="article:publisher" content="https://rugh.dev/" />
  <meta property="author" content="Stephen Rugh" />
  <meta property="article:published_time" content="2022-04-03T12:00:00+00:00" />
</svelte:head>

<article>
  <script
    async
    src="https://cpwebassets.codepen.io/assets/embed/ei.js"></script>

  {@html mdDarkmode.html}
</article>

<style>
  /* TODO: Fix this hacky hack. */
  :global(img[alt="elden ring splash"]) {
    max-width: max-content !important;
    opacity: 1 !important;
  }

  @media (max-width: 960px) {
    :global(img[alt="elden ring splash"]) {
      max-width: 85% !important;
    }
  }
</style>
